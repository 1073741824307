* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
}

h1,
h2 {
  font-family: "Unica One", cursive;
}

.date {
  font-weight: bold !important;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

article {
  position: relative;
  width: 250px;
  transition: all 0.3s ease;
}

article img:first-child {
  box-shadow: 0 60px 60px -60px rgba(223, 41, 53, 0.5);
  border-radius: 20px;
  object-fit: cover;
  width: 100%;
}

article img:last-child {
  position: absolute;
  max-width: 60%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(25%);
  transition: all 0.3s ease;
  opacity: 0;
}

article:hover {
  transform: perspective(250px) rotateX(3deg) translateY(-5%) translateZ(0);
}

article::before {
  content: "";
  position: absolute;
  bottom: 15%;
  width: 100%;
  height: 18em;
  background-image: linear-gradient(
    to bottom,
    transparent 10%,
    rgba(223, 41, 53, 1) 30%,
    rgba(183, 27, 37, 1) 75%
  );
  opacity: 0;
  transition: all 0.3s ease;
  border-radius: 20px;
}

article:hover::before {
  opacity: 1;
}

article:hover img:last-child {
  opacity: 1;
  transform: translateY(-25%);
}

.react-multi-carousel-list {
  height: 30em;
}

.react-multi-carousel-track {
  align-items: center;
}

.react-multi-carousel-dot--active button {
  background: #df2935 !important;
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
  }
}
